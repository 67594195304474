import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
export default {
  name: 'DateWarning1',
  components: {
    PageHeader
  },
  mixins: [tableMixin],
  data() {
    return {
      IsZoreStock: true,
      formSearch: {
        KeyWords: '',
        BrandName: '',
        InvName: '',
        InvCode: '',
        Type: 1, //  1 :库存不足  2： 库存积压 3：库存滞销 4： 效期预警
        IsZoreStock: 0 // 如果是查询0 库存 传1 不是传 0
      }
    }
  },
  watch: {
    IsZoreStock(a) {
      if (a) {
        return this.formSearch.IsZoreStock = 1
      }
      if (!a) {
        return this.formSearch.IsZoreStock = 0
      }
    }
  },
  methods: {
    async getTableDataAsync(page, pageSize) {
      // this.tableData = []
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      const response = await this.$api.GetStockWarningListByType(this.formSearch)
      console.log('response', response)
      if (response.RetCode !== '0') return this.$message.error('错误：' + response.Message)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      return response
    }
  },
  mounted() {
    console.log('this.$route.params', this.$route.params)
    this.formSearch.Type = this.$route.params.type
  }
}
