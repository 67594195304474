<template>
  <div class="date-warning">
    <PageHeader title="库存效期预警" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div class="flex">
        <div
          class="ModelBttton-white-30 m-right-10"
        >
          <i class="el-icon-upload2 f15 p-right-10" /> 导出
        </div>
        <!-- @click="() => $refs.FieldManagement.openSetFieldManagement()" -->
        <div class="ModelBttton-white-30">
          <i class="el-icon-setting f15 p-right-5" />   字段管理
        </div>
      </div>
      <div class="flex m-lr-10 SearchBox_30" style="flex: 1">
        <el-input
          v-model="formSearch.KeyWords"
          size="medium"
          placeholder="请输入内容"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div
            slot="append"
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <el-switch
        v-if="formSearch.Type === 1"
        v-model="IsZoreStock"
        active-color="#333"
        class="m-lr-20"
        active-text="零库存商品"
      />
      <div class="flex m-left-20">
        <el-button size="small" @click="() => $router.back()">返回</el-button>
      </div>
    </div>

    <!-- 库存不足 start-->
    <div v-if="formSearch.Type === 1" class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        class="TableModel8"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        :max-height="$minCommon.SetTableHeight(340)"
        row-key="IDX"
        border
        :fit="true"
      >
        <af-table-column fixed="left" type="selection" width="40" />
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column prop="MaterialTypeName" label="物料类型" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格\型号" show-overflow-tooltip />
        <el-table-column prop="MRCStockUnitN" label="单位" show-overflow-tooltip />
        <el-table-column prop="StockNum" label="可用数量" show-overflow-tooltip />
        <el-table-column prop="ZTNum" label="在途库存" show-overflow-tooltip />
        <el-table-column prop="DXNum" label="待销库存" show-overflow-tooltip />
        <el-table-column prop="LastSaleNum" label="上月销量" show-overflow-tooltip />
        <el-table-column prop="InventoryDown" label="最小补货数量" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            {{ scope.row.InventoryDown - scope.row.StockNum }}
          </template>
        </el-table-column>
        <el-table-column prop="StockNum" label="最大补货数量" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            {{ scope.row.InventoryUp - scope.row.StockNum }}
          </template>
        </el-table-column>
        <el-table-column prop="InventoryDown" label="库存下限" show-overflow-tooltip />
        <el-table-column prop="InventoryUp" label="库存上限" show-overflow-tooltip />
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip />
        <el-table-column prop="ProductFactory" label="生产厂家" show-overflow-tooltip />
        <el-table-column prop="NickName" label="物料简称" show-overflow-tooltip />
        <el-table-column prop="SourceAreaName" label="原产地" show-overflow-tooltip />
        <el-table-column prop="TransportConditionName" label="运输条件" show-overflow-tooltip />
        <el-table-column prop="StorageConditionName" label="储存条件" show-overflow-tooltip />
        <el-table-column prop="InvTypeName" label="物料分类" show-overflow-tooltip />
        <el-table-column prop="StockCode" label="物料所在仓库" show-overflow-tooltip width="100" />
        <el-table-column prop="DINum" label="UDI码" show-overflow-tooltip />
        <el-table-column prop="MaterialRemark" label="备注" show-overflow-tooltip />
        <af-table-column fixed="right" prop="address" align="center" label="操作" width="50">
          <template>
            <el-popover
              placement="left"
              trigger="hover"
              :width="100"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div class="OperationButton">
                  <el-link :underline="false"><i class="el-icon-view p-right-10" />查看附件</el-link>
                </div>
                <div class="OperationButton">
                  <el-link :underline="false"><i class="el-icon-download p-right-10" />下载附件</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </af-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <!-- 库存不足 end-->

    <!-- 库存积压 start-->
    <div v-if="formSearch.Type === 2" class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        class="TableModel8"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        :max-height="$minCommon.SetTableHeight(340)"
        row-key="IDX"
        border
        :fit="true"
      >
        <af-table-column fixed="left" type="selection" width="40" />
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column prop="MaterialTypeName" label="物料类型" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格\型号" show-overflow-tooltip />
        <el-table-column prop="MRCStockUnitN" label="单位" show-overflow-tooltip />
        <el-table-column prop="StockNum" label="可用数量" show-overflow-tooltip />
        <el-table-column prop="ZTNum" label="在途库存" show-overflow-tooltip />
        <el-table-column prop="DXNum" label="待销库存" show-overflow-tooltip />
        <el-table-column prop="LastSaleNum" label="上月销量" show-overflow-tooltip />
        <el-table-column prop="InventoryDown" label="最小补货数量" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.InventoryDown - scope.row.StockNum }}
          </template>
        </el-table-column>
        <el-table-column prop="StockNum" label="最大补货数量" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.InventoryUp - scope.row.StockNum }}
          </template>
        </el-table-column>
        <el-table-column prop="InventoryDown" label="库存下限" show-overflow-tooltip />
        <el-table-column prop="InventoryUp" label="积压数量" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.StockNum - scope.row.InventoryUp }}
          </template>
        </el-table-column>
        <el-table-column prop="InventoryUp" label="库存上限" show-overflow-tooltip />
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip />
        <el-table-column prop="ProductFactory" label="生产厂家" show-overflow-tooltip />
        <el-table-column prop="NickName" label="物料简称" show-overflow-tooltip />
        <el-table-column prop="SourceAreaName" label="原产地" show-overflow-tooltip />
        <el-table-column prop="TransportConditionName" label="运输条件" show-overflow-tooltip />
        <el-table-column prop="StorageConditionName" label="储存条件" show-overflow-tooltip />
        <el-table-column prop="InvTypeName" label="物料分类" show-overflow-tooltip />
        <el-table-column prop="StockCode" label="物料所在仓库" show-overflow-tooltip />
        <el-table-column prop="DINum" label="UDI码" show-overflow-tooltip />
        <el-table-column prop="MaterialRemark" label="备注" show-overflow-tooltip />

        <af-table-column fixed="right" prop="address" align="center" label="操作" width="50">
          <template>
            <el-popover
              placement="left"
              trigger="hover"
              :width="100"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div class="OperationButton">
                  <el-link :underline="false"><i class="el-icon-view p-right-10" />查看附件</el-link>
                </div>
                <div class="OperationButton">
                  <el-link :underline="false"><i class="el-icon-download p-right-10" />下载附件</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </af-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <!-- 库存积压 end-->

    <!-- 库存滞销 start-->
    <div v-if="formSearch.Type === 3" class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        class="TableModel8"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        :max-height="$minCommon.SetTableHeight(340)"
        row-key="IDX"
        border
        :fit="true"
      >
        <af-table-column fixed="left" type="selection" width="40" />
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column prop="MaterialTypeName" label="物料类型" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格\型号" show-overflow-tooltip />
        <el-table-column prop="MRCStockUnitN" label="单位" show-overflow-tooltip />
        <el-table-column prop="StockNum" label="可用数量" show-overflow-tooltip />
        <el-table-column prop="LotNum" label="入库批号\序列号" show-overflow-tooltip />
        <el-table-column prop="WriteTime" label="入库时间" show-overflow-tooltip />
        <el-table-column prop="LotNo" label="入库批次" show-overflow-tooltip />
        <el-table-column prop="BaseDays" label="滞销天数" show-overflow-tooltip />
        <el-table-column prop="ExpiredDate" label="失效期截止" show-overflow-tooltip />
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip />
        <el-table-column prop="ProductFactory" label="生产厂家" show-overflow-tooltip />
        <el-table-column prop="NickName" label="物料简称" show-overflow-tooltip />
        <el-table-column prop="SourceAreaName" label="原产地" show-overflow-tooltip />
        <el-table-column prop="TransportConditionName" label="运输条件" show-overflow-tooltip />
        <el-table-column prop="StorageConditionName" label="储存条件" show-overflow-tooltip />
        <el-table-column prop="InvTypeName" label="物料分类" show-overflow-tooltip />
        <el-table-column prop="StockCode" label="物料所在仓库" show-overflow-tooltip />
        <el-table-column prop="DINum" label="UDI码" show-overflow-tooltip />
        <el-table-column prop="MaterialRemark" label="备注" show-overflow-tooltip />

        <af-table-column fixed="right" prop="address" align="center" label="操作" width="50">
          <template>
            <el-popover
              placement="left"
              trigger="hover"
              :width="100"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div class="OperationButton">
                  <el-link :underline="false"><i class="el-icon-view p-right-10" />查看附件</el-link>
                </div>
                <div class="OperationButton">
                  <el-link :underline="false"><i class="el-icon-download p-right-10" />下载附件</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </af-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <!-- 库存滞销 end-->

    <!-- 效期预警 start-->
    <div v-if="formSearch.Type === 4" class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        class="TableModel8"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        :max-height="$minCommon.SetTableHeight(340)"
        row-key="IDX"
        border
        :fit="true"
      >
        <af-table-column fixed="left" type="selection" width="40" />
        <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column prop="MaterialTypeName" label="物料类型" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格\型号" show-overflow-tooltip />
        <el-table-column prop="MRCStockUnitN" label="单位" show-overflow-tooltip />
        <el-table-column prop="StockNum" label="可用数量" show-overflow-tooltip />
        <el-table-column prop="LotNum" label="入库批号\序列号" show-overflow-tooltip />
        <el-table-column prop="ExpiredDate" label="失效期截止" show-overflow-tooltip />
        <el-table-column prop="LotNo" label="入库批次" show-overflow-tooltip />
        <el-table-column prop="BaseDays" label="剩余失效天数" show-overflow-tooltip />
        <el-table-column prop="WriteTime" label="入库时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.WriteTime }}
          </template>
        </el-table-column>
        <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip />
        <el-table-column prop="ProductFactory" label="生产厂家" show-overflow-tooltip />
        <el-table-column prop="NickName" label="物料简称" show-overflow-tooltip />
        <el-table-column prop="SourceAreaName" label="原产地" show-overflow-tooltip />
        <el-table-column prop="TransportConditionName" label="运输条件" show-overflow-tooltip />
        <el-table-column prop="StorageConditionName" label="储存条件" show-overflow-tooltip />
        <el-table-column prop="InvTypeName" label="物料分类" show-overflow-tooltip />
        <el-table-column prop="StockCode" label="物料所在仓库" show-overflow-tooltip />
        <el-table-column prop="DINum" label="UDI码" show-overflow-tooltip />
        <el-table-column prop="MaterialRemark" label="备注" show-overflow-tooltip />

        <af-table-column fixed="right" prop="address" align="center" label="操作" width="50">
          <template>
            <el-popover
              placement="left"
              trigger="hover"
              :width="100"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div class="OperationButton">
                  <el-link :underline="false"><i class="el-icon-view p-right-10" />查看附件</el-link>
                </div>
                <div class="OperationButton">
                  <el-link :underline="false"><i class="el-icon-download p-right-10" />下载附件</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </af-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <!-- 效期预警 end-->
  </div>
</template>
<script>
import Index from '@/minxin/management/date-warning'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>

